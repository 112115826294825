import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  label{
    font-size: 10px;
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const Checkbox = () => {
    return (
      <>
        <Container>
            <input type="checkbox" id="privacy-checkbox" name="privacy-checkbox" required/>
            
            <label htmlFor="privacy-checkbox">
              Ho letto l'<a href="/privacy-policy" target="_blank" >informativa privacy</a> e acconsento alla memorizzazione dei miei dati nel vostro archivio secondo quanto stabilito dal regolamento europeo per la protezione dei dati personali n. 679/2016, GDPR. (Potrai cancellarli o chiederne una copia facendo esplicita richiesta a maucieri.eugenio@gmail.com)
            </label>
      </Container>
      {/* {
        error && <HelperText>Si prega di accettare l'informativa privacy</HelperText>
      } */}
      </>
    )
}

export default Checkbox;