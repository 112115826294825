import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const  BtnCircle = styled.div`
    text-decoration: none;
    align-items: center;
    color: inherit;
    position: relative;
    top: 2rem;
    margin: auto;
    padding: 19px 22px;
    transition: all .2s ease;
    cursor: pointer;
    transform: ${props => props.open ? 100 + '%' : 'unset'};
    display: ${props => props.open ?  'inline-flex' : 'unset'};
    margin: ${props => props.open ? '0.5rem auto 2rem'  : 'auto'};
    top: ${props => props.open ? 0 : 'unset'};
    @media (max-width: 960px) {
        transform: scale(.96);
        display: inline-flex;
        top: 0;
    }
    :before {
        content: "";
        position: absolute;
        top: 6px;
        left: 0;
        display: block;
        border-radius: 28px;
        background: ${props => props.dark ? ({ theme }) => theme.primaryColor : props.light ? ({ theme }) => theme.white : ({ theme }) => theme.opacitySecondaryColor};
        width: ${props => props.open ? 100 + '%' : 45 + 'px'};
        height: 45px;
        transition: all .3s ease;
        @media (max-width: 960px) {
            width: 100%;
        }
    }
    :hover {
        :before {
            width: 100%;
            background: ${props => props.dark ? ({ theme }) => theme.primaryColor : props.light ? ({ theme }) => theme.white : ({ theme }) => theme.opacitySecondaryColor};
        }
        svg {
            transform: translateX(0);
        }
        &:active {
            transform: scale(.96);
        }
    }
    span {
        position: relative;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        text-transform: uppercase;
        vertical-align: middle;
        color: ${props => props.dark ? ({ theme }) => theme.white : props.light ? ({ theme }) => theme.secondaryColor : ({ theme }) => theme.text};
    }
    svg {
        position: relative;
        top: 0;
        margin-left: 10px;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: ${props => props.dark ? ({ theme }) => theme.white : props.light ? ({ theme }) => theme.secondaryColor : ({ theme }) => theme.text};
        stroke-width: 2;
        transform: translateX(-5px);
        transition: all .3s ease;
    }
`;

const CircleButton = ({children, link, open, dark, light}) => {
    return (
        <BtnCircle as={Link} to={link} open={open} dark={dark} light={light}>
            <span>{children}</span>
            <svg width="13px" height="10px" viewBox="0 0 13 10">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
        </BtnCircle>
    )
}

export default CircleButton;