import React from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from 'react-reveal/Fade'
import InputOutlined from "../components/input/InputOutlined"
import CircleButton from "../components/button/circleButton";
import Social from "../components/social"
import TextArea from "../components/textArea/textAreaOutlined"
import Checkbox from "../components/input/Checkbox"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faEnvelope, faPhoneAlt, faCalendarAlt } from "@fortawesome/free-solid-svg-icons"

const Container = styled.div`
    display: inline-block;
    width: 100%;
    padding: 0 5vw;
    @media (max-width: 767px) {
        display: block;
        margin: auto;
    }
`;

const WrapperContactData = styled.div`
    width: 40%;
    float: right;
    color: ${({ theme }) => theme.primaryColor};
    @media (max-width: 767px) {
        width: 100%;
    }
`;

const ContactData = styled.div`
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;
    .contact-icon {
        display: inline;
        margin-right: 10px;
    }
    span {
        font-size: 18px;
        font-weight: 400;
    }
    hr {
        background-color: lightgrey;
        border: none;
        display: block;
        height: 1.5px;
        overflow: visible;
        position: relative;
        width: 100%;
        margin-bottom: 0.5rem;
        :before {
            background: linear-gradient(90deg,rgba(65,236,241,1) 0%,rgba(49,104,155,1) 100%);
            content: '';
            display: block;
            height: 1.5px;
            left: 0;
            position: absolute;
            top: 0;
            width: 25%;
            z-index: 1;
        }
    }
    p {
        width: 100%;
        float: right;
        margin: 0;
        font-weight: 300;
        line-height: 20px;
        font-size: 14px;
    }
`;

const SocialContainer = styled.div`
    width: max-content;
`;

const ContactForm = styled.div`
    float: left;
    width: 60%;
    border-radius: 10px;
    display: grid;
    grid-gap: 15px;
    /* vertical-align: middle;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 70px 70px 70px 120px 60px; */
    padding-right: 5vw;
    margin-bottom: 60px;
    p{
        color: ${({ theme }) => theme.primaryColor};
        margin: 0;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding-left: 0;
    }
`;

const WrapperMap = styled.div`
    width: 100%;
    padding: 0 5vw 3vh 5vw;
`;

export default function Contatti() {
    const contactForm = useFormik({
        initialValues: {
            fistName: "",
            lastName: "",
            email: "",
            telephone: "",
            message: "",
            privacy: false,
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .max(20, 'Il Nome deve essere minore di 20 caratteri')
                .required('Campo obbligatorio'),
            lastName: Yup.string()
                .max(20, 'Il Cognome deve essere minore di 20 caratteri')
                .required('Campo obbligatorio'),
            email: Yup.string().required('Campo obbligatorio'),
            privacy: Yup.boolean().required('Campo obbligatorio')
        }),
        onSubmit: async (values, { resetForm }) => {
        },
    });
    return (
        <Layout pageTitle="Contatti - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
            <Fade left duration={1000}>
                <h1>Contatti</h1>
            </Fade>
            <Container>
                <ContactForm>
                    <p>Compila il modulo qui sotto per richiedere informazioni e sarai ricontattato al più presto dal nostro team.</p>
                    <InputOutlined
                        name="firstName"
                        value={contactForm.values.firstName || ""}
                        onChange={contactForm.handleChange}
                        error={contactForm.errors.firstName}
                        placeholder=" "
                        type="text"
                        label="Nome"
                        color={"#084A81"}
                    />
                    <InputOutlined name="lastName" placeholder=" " type="text" label="Cognome" color={"#084A81"} />
                    <InputOutlined name="email" placeholder=" " type="text" label="Email" color={"#084A81"} />
                    <InputOutlined  name="telephone" placeholder=" " type="text" label="Telefono" color={"#084A81"} />
                    <TextArea name="message" placeholder=" " label="Messaggio" color={"#084A81"} />
                    <Checkbox />
                    <CircleButton
                        children={"Contattaci"}
                    />
                </ContactForm>
                <WrapperContactData>
                    <ContactData>
                        <span><FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" /> Indirizzo</span>
                        <hr />
                        <p>Via Giacomo Matteotti 61, 06028 Sigillo (PG)</p>
                    </ContactData>
                    <ContactData>
                        <span><FontAwesomeIcon icon={faCalendarAlt} className="contact-icon" /> Orari di apertura</span>
                        <hr />
                        <p>Lunedí-Venerdí: 8:00 - 20:00 <br />Sabato: 8:00 - 16:30<br />Domenica: CHIUSO</p>
                    </ContactData>
                    <ContactData>
                        <span><FontAwesomeIcon icon={faPhoneAlt} className="contact-icon" /> Telefono</span>
                        <hr />
                        <p>+39 075.917.70.98</p>
                        <p>+39 391.164.81.95</p>
                    </ContactData>
                    <ContactData>
                        <span><FontAwesomeIcon icon={faEnvelope} className="contact-icon" /> Email</span>
                        <hr />
                        <p>maucieri.eugenio@gmail.com</p>
                    </ContactData>
                    <ContactData>
                        <span>Social</span>
                        <hr />
                        <SocialContainer>
                           <Social />
                        </SocialContainer>
                    </ContactData>
                </WrapperContactData>
            </Container>
            <WrapperMap>
                    <div class="mapouter">
                        <div class="gmap_canvas">
                            <iframe width="100%" height="700" id="gmap_canvas" title="my-map" src="https://maps.google.com/maps?q=Via%20Giacomo%20Matteotti,%2061,%2006028%20Sigillo%20PG&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        </div>
                    </div>
                </WrapperMap>
        </Layout>
    )
}