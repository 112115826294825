import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextField = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled.label`
  position: absolute;
  font-size: 12px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${props => props.color || "#FFFFFF"};
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: .1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  background-color: transparent;
  color: ${props => props.color || "#FFFFFF"};
`;

const Input = styled.input`
  outline: none;
  border: 1px solid ${props => props.color || "#FFFFFF"};
  border-radius: 5px;
  padding: 0.7rem 0.7rem;
  color: ${props => props.color || "#FFFFFF"};
  transition: 0.1s ease-out;
  background-color: transparent;
  width: 100%;
  outline: none;
  &:focus {
    border: 2px solid;
  }
  &:focus + label {
    color: ${props => props.color || "#FFFFFF"};
    top: 7px;
    transform: translateY(-100%) scale(.9);
    background: ${({ theme }) => theme.white};
  }
  &:not(:placeholder-shown) + label {
    top: 7px;
    transform: translateY(-90%) scale(.9);
    background: ${({ theme }) => theme.white};
  }
`;

const HelperText = styled.div`
  margin-top: -20px;
  font-weight: 100;
  color: red;
`;

const InputOutlined = ({name, value, onChange, type, placeholder, label, error, color}) => {
    return (
      <>
        <Container>
          <TextField>
            <Input
              name={name}
              value={value}
              onChange={onChange}
              placeholder={placeholder || " "}
              type={type}
              color={color}
              id={`input-contact-form-${name}`}
            />
            <Label color={color} for={`input-contact-form-${name}`}>{label}</Label>
          </TextField>
      </Container>
      {
        error && <HelperText>{error}</HelperText>
      }
      </>
    )
}

export default InputOutlined;